<template>
  <ul class="search-keyword-list">
    <a
      v-for="keyword in hotTrendKeywords"
      :key="keyword"
      @click="() => searchByHotTrendKeyword(keyword)"
      class="search-keyword-list__cell"
    >
      <i class="search-keyword-list__icon jirafficon jirafficon-loupe"></i>
      <div class="search-keyword-list__keyword">
        {{ keyword }}
      </div>
    </a>
  </ul>
</template>

<script lang="ts">
import Vue from 'vue'

interface Props {
  hotTrendKeywords: string[]
  searchByHotTrendKeyword(keyword: string): void
}

export default Vue.extend<{}, {}, {}, Props>({
  props: {
    hotTrendKeywords: {
      type: Array,
      required: true,
    },
    searchByHotTrendKeyword: {
      type: Function,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
.search-keyword-list {
  display: flex;
  flex-wrap: wrap;

  .search-keyword-list__cell {
    transition: 0.1s ease transform;
    cursor: pointer;
    padding: $space-S;
    margin: $space-S;
    margin-left: 0;
    background: $co-white;
    border-radius: $round;
    display: flex;
    align-items: center;
    box-shadow: $shadow-box;
    color: $co-black;

    &:hover {
      transform: scale(1.01);
    }
  }

  .search-keyword-list__icon {
    margin-right: $space-S;
    color: $co-gray;
  }
}
</style>
