<template>
  <label class="form__label">
    <slot />
    <span class="form__required" v-if="required"> ※必須 </span>
  </label>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    required: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
