var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "just-purchased-modal" }, [
    _c("div", { staticClass: "just-purchased-modal__product-wrapper" }, [
      _c("img", {
        staticClass: "just-purchased-modal__product-image",
        attrs: { src: _vm.productImageSrc },
      }),
    ]),
    _vm._v(" "),
    _c("img", {
      staticClass: "mt-m",
      attrs: { src: _vm.jutPurchasedImageUrl },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "my-m text-center just-purchased-modal__sub-text" },
      [_vm._v("\n    " + _vm._s(_vm.subText) + "\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt-s text-bold just-purchased-modal__sub-title" },
      [_vm._v("\n    商品をシェアしよう！\n  ")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "mx-0 my-m btn btn-x just-purchased-modal__x-share-button",
        on: {
          click: function ($event) {
            return _vm.shareX()
          },
        },
      },
      [
        _c("i", {
          staticClass:
            "btn__left-icon jirafficon jirafficon-x just-purchased-modal__x-share-icon",
        }),
        _vm._v(" "),
        _c("span", [_vm._v("Xでシェア")]),
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "my-m btn btn--gray just-purchased-modal__close-button",
        on: {
          click: function ($event) {
            return _vm.handleClickCloseButton()
          },
        },
      },
      [
        _c("span", { staticClass: "just-purchased-modal__button-sub-text" }, [
          _vm._v("\n      取引画面を見る\n    "),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }