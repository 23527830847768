var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "item-estimates" }, [
    _c(
      "div",
      { staticClass: "item-estimates__row item-estimates__row-header" },
      [
        _c("div", { staticClass: "item-estimates__subject" }, [
          _c("div", [_vm._v("売上金")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-estimates__dd",
              attrs: { "aria-labelledby": "売上金" },
            },
            [_vm._v("\n        " + _vm._s(_vm.incomePrice()) + "\n      ")]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "item-estimates__row" }, [
      _c("div", { staticClass: "item-estimates__details" }, [
        _c("div", [_vm._v("商品価格")]),
        _vm._v(" "),
        _c("div", { staticClass: "item-estimates__dd" }, [
          _vm._v(_vm._s(_vm.sales())),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-estimates__details" }, [
        _c("div", [_vm._v("販売手数料")]),
        _vm._v(" "),
        _c("div", { staticClass: "item-estimates__dd" }, [
          _vm._v(_vm._s(_vm.chargeText())),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "item-estimates__row item-estimates__row-header" },
      [
        _c(
          "div",
          {
            staticClass:
              "item-estimates__subject item-estimates__subject-description",
          },
          [
            _c("div", [_vm._v("販売価格（購入者負担金額）")]),
            _vm._v(" "),
            _vm.sellingPriceDescription
              ? _c("div", { staticClass: "item-estimates__description" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.sellingPriceDescription()) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "item-estimates__amount" }, [
              _vm._v(_vm._s(_vm.sellingPrice())),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "item-estimates__row" },
      [
        _vm._l(_vm.sellingPriceDetails(), function (detail) {
          return [
            _c(
              "div",
              { key: detail.label, staticClass: "item-estimates__details" },
              [
                _c("div", { key: detail.label }, [
                  _vm._v("\n          " + _vm._s(detail.label) + "\n        "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    key: detail.label + detail.value,
                    staticClass: "item-estimates__dd",
                  },
                  [_vm._v("\n          " + _vm._s(detail.value) + "\n        ")]
                ),
              ]
            ),
          ]
        }),
      ],
      2
    ),
    _vm._v(" "),
    _vm.earningPointsDisplayable()
      ? _c(
          "div",
          { staticClass: "item-estimates__row item-estimates__row-header" },
          [_vm._m(0)]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.earningPointsDisplayable()
      ? _c("div", { staticClass: "item-estimates__row" }, [
          _c("div", { staticClass: "item-estimates__details" }, [
            _c("div", { staticClass: "item-estimates__dd" }, [
              _vm._v(_vm._s(_vm.earningPointsText())),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "item-estimates__subject" }, [
      _c("div", [_vm._v("獲得ポイント")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }