<template>
  <div :class="'form__input--select-wrapper ' + selectorWrapperClass">
    <select
      :name="name"
      :class="'form__input ' + additionalClassName() + '' + selectorClass"
      :value="value"
      @input="updateValue"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      :id="selectorId"
    >
      <slot />
    </select>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    value: {},
    inputType: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    selectorId: {
      type: String,
    },
    selectorClass: {
      type: String,
    },
    selectorWrapperClass: {
      type: String,
    },
  },
  methods: {
    updateValue(e: Event): void {
      const { target } = e
      if (!target || !(target instanceof HTMLSelectElement)) {
        throw new TypeError()
      }
      this.$emit('input', target.value)
      this.$emit('change', target.value)
    },
    additionalClassName(): string {
      return this.$props.error ? 'form__input--field-error' : ''
    },
  },
})
</script>
<style scope lang="scss">
.has-value {
  color: $co-black;
}

.blank-value {
  color: $co-gray;
}
</style>
