<template>
  <input
    :class="'form__input ' + additionalClassName"
    :placeholder="placeholder"
    :value="value"
    :type="type"
    @input="updateValue"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  />
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    placeholder: {
      type: String,
      default: '入力してください',
    },
    error: {
      type: Boolean,
      default: false,
    },
    value: {},
    type: {
      type: String,
      default: 'text',
    },
  },
  computed: {
    additionalClassName(): string {
      return this.$props.error ? 'form__input--field-error' : ''
    },
  },
  methods: {
    updateValue(e: Event): void {
      const { target } = e
      if (!target || !(target instanceof HTMLInputElement)) {
        throw new TypeError()
      }
      this.$emit('input', target.value)
      this.$emit('change', target.value)
    },
  },
})
</script>
