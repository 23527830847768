<template>
  <div>
    <form-group
      class="form__group--column"
      v-for="(field, index) in template.fields"
      :key="generateCustomAttributeKey(field.name)"
    >
      <form-label :required="required">{{ field.label }}</form-label>
      <input type="hidden" :name="makeAtrrName(index)" :value="field.name" />
      <form-control>
        <select-box
          :name="makeAttrValue(index)"
          selector-id="item_custom_attribute"
          :selector-class="makeSelectedClass(field.name)"
          :selector-wrapper-class="selectorWrapperClass"
          v-model="selectedAttribute[field.name]"
          v-if="field.field_type === 'selectbox'"
        >
          <option value="">---</option>
          <option
            v-for="{ label, value } in field.options"
            :value="value"
            :key="value"
          >
            {{ label }}
          </option>
        </select-box>
        <form-modal-selector-btn
          :name="field.name"
          :options="field.options"
          v-else-if="field.field_type === 'radio'"
        />
      </form-control>
    </form-group>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import formable from '~/components/mixins/formable'
import FormControl from '~/components/molecules/form/FormControl.vue'

interface Data {
  selectedAttribute: { [name: string]: string }
}

interface Methods {
  makeAtrrName(index: number): string
  makeAttrValue(index: number): string
  makeSelectedClass(name: string): string
  generateCustomAttributeKey(name: string): string
}

interface Computed {}

interface Props {
  template: Api.CustomAttributes.Response
  selectorClass: string
  selectorWrapperClass: string
  required: boolean
  selectedAttributesParam: { [name: string]: string }
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { FormControl },
  mixins: [formable],
  data() {
    return {
      selectedAttribute: { ...this.selectedAttributesParam },
    }
  },
  created() {
    if (
      this.selectedAttributesParam != null &&
      this.selectedAttributesParam.size != null
    ) {
      this.selectedAttribute = this.selectedAttributesParam
    }
  },
  methods: {
    makeAtrrName(index: number) {
      return `item[custom_attributes][${index}][name]`
    },
    makeAttrValue(index: number) {
      return `item[custom_attributes][${index}][values][]`
    },
    makeSelectedClass(name: string) {
      return `${this.selectorClass} ${
        this.selectedAttribute[name] ? 'has-value' : 'blank-value'
      }`
    },
    generateCustomAttributeKey(name: string) {
      return name + new Date().getTime()
    },
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
    selectorClass: {
      type: String,
    },
    selectorWrapperClass: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    selectedAttributesParam: {
      type: Object,
    },
  },
  watch: {
    template: {
      handler() {
        const selectedAttributeParamsRep: { [name: string]: string } = {
          ...this.selectedAttribute,
        }

        this.template.fields.forEach((field) => {
          if (this.selectedAttribute[field.name]) {
            const fieldValue =
              this.selectedAttribute[field.name].constructor.name === 'Array'
                ? this.selectedAttribute[field.name][0]
                : this.selectedAttribute[field.name]

            if (field.options.map((opt) => opt.value).includes(fieldValue)) {
              selectedAttributeParamsRep[field.name] = fieldValue
            } else {
              selectedAttributeParamsRep[field.name] = field.default_value ?? ''
            }
          } else {
            selectedAttributeParamsRep[field.name] = field.default_value ?? ''
          }
        })

        this.selectedAttribute = { ...selectedAttributeParamsRep }
      },
      deep: true,
    },
  },
})
</script>
