<template>
  <div class="editor">
    <editor-content class="editor__content editable-content" :editor="editor" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { Editor, EditorContent } from 'tiptap'

@Component({
  components: {
    EditorContent,
  },
  props: {
    editor: Editor,
  },
})
export default class extends Vue {
  // FIXME
  /* eslint-disable */
  // ts-ignore
  private editor: any
  /* eslint-enable */
}
</script>

<style lang="scss">
//XXX: tiptap内のstyleに触るためscopedを外しているので競合に注意してください
.editor {
  .editor__content {
    border: 1px solid #c7c7c7;
    background: $co-white;
    border-radius: $round;
    > div {
      min-height: 300px;
      padding: $space-S;
    }
  }
}
</style>
