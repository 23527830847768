<template>
  <div class="lottery-result-prize-cards">
    <lottery-result-prize-card
      v-for="lotteryResultPrize in lotteryResultPrizes"
      :key="lotteryResultPrize.id"
      :lotteryResultPrize="lotteryResultPrize"
      @onOpenConvertCouponModal="onOpenConvertCouponModal"
      @onTransitRequestShippingPage="onTransitRequestShippingPage"
      :isMypage="is_mypage"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { AxiosResponse } from 'axios'
import ModalController from '~/controllers/modal_controller'
import { findController } from '~/lib/stimulus'
import { postMypageOripaLotteryResultPrizesConvert } from '~/api/mypage/oripa/lottery_result_prizes/convert'
import LotteryResultPrizeCard from '~/components/organisms/oripa/lotteryResultPrizes/LotteryResultPrizeCard.vue'
import ConvertCouponModal from '~/components/organisms/oripa/lotteryResultPrizes/ConvertCouponModal.vue'

interface Data {
  modal?: ModalController
  lotteryResultPrizes: Model.LotteryResultPrize[]
}

interface Methods {
  onOpenConvertCouponModal(lotteryResultPrize: Model.LotteryResultPrize): void
  onClickConvertCouponButton(lotteryResultPrize: Model.LotteryResultPrize): void
  onClickCancelButton(): void
  onTransitRequestShippingPage(id: number): void
}

interface Computed {}

interface Props {
  lottery_result_prizes: Model.LotteryResultPrize[]
  is_mypage: boolean
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    LotteryResultPrizeCard,
  },
  created(): void {
    this.modal = findController<ModalController>('modal')
    this.lotteryResultPrizes = this.lottery_result_prizes
  },
  data() {
    return {
      modal: undefined,
      lotteryResultPrizes: [],
    }
  },
  props: {
    lottery_result_prizes: {
      type: Array,
      required: true,
    },
    is_mypage: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onOpenConvertCouponModal(
      lotteryResultPrize: Model.LotteryResultPrize
    ): void {
      if (!this.modal) throw new TypeError()

      this.modal.showWithVue(ConvertCouponModal, {
        propsData: {
          lotteryResultPrize,
          onClickCancelButton: this.onClickCancelButton,
          onClickConvertCouponButton: this.onClickConvertCouponButton,
        },
      })
    },
    onClickConvertCouponButton(
      lotteryResultPrize: Model.LotteryResultPrize
    ): void {
      postMypageOripaLotteryResultPrizesConvert(lotteryResultPrize.id).then(
        (
          response: AxiosResponse<Api.Oripa.LotteryResultPrizes.Convert.Post.Response>
        ) => {
          if (!this.modal) throw new TypeError()

          this.modal.showWithVue(ConvertCouponModal, {
            propsData: {
              coupon: response.data.coupon,
              point_detail: response.data.point_detail,
              personal: response.data.personal,
              onClickCancelButton: this.onClickCancelButton,
              onClickConvertCouponButton: this.onClickConvertCouponButton,
            },
          })

          this.lotteryResultPrizes = this.lotteryResultPrizes.map(
            (prize: Model.LotteryResultPrize) => {
              if (prize.id !== lotteryResultPrize.id) {
                return prize
              }

              const cloned_prize: Model.LotteryResultPrize = { ...prize }
              cloned_prize.status = 'converted'

              return cloned_prize
            }
          )
        }
      )
    },
    onClickCancelButton(): void {
      if (!this.modal) throw new TypeError()

      this.modal.close()
    },
    onTransitRequestShippingPage(id: number): void {
      window.location.pathname = `/oripa/lottery_result_prizes/${id}/shippings/new`
    },
  },
})
</script>

<style scoped lang="scss">
.lottery-result-prize-cards {
  margin: $space-M auto;
  max-width: 800px;
}
</style>
