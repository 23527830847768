<template>
  <div class="just-purchased-modal">
    <div class="just-purchased-modal__product-wrapper">
      <img class="just-purchased-modal__product-image" :src="productImageSrc" />
    </div>
    <img class="mt-m" :src="jutPurchasedImageUrl" />
    <div class="my-m text-center just-purchased-modal__sub-text">
      {{ subText }}
    </div>
    <div class="mt-s text-bold just-purchased-modal__sub-title">
      商品をシェアしよう！
    </div>
    <button
      class="mx-0 my-m btn btn-x just-purchased-modal__x-share-button"
      @click="shareX()"
    >
      <i
        class="btn__left-icon jirafficon jirafficon-x just-purchased-modal__x-share-icon"
      />
      <span>Xでシェア</span>
    </button>
    <button
      class="my-m btn btn--gray just-purchased-modal__close-button"
      @click="handleClickCloseButton()"
    >
      <span class="just-purchased-modal__button-sub-text">
        取引画面を見る
      </span>
    </button>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import jusPurchasedImage from 'images/trade/purchased_bold.svg'
import { findController } from '~/lib/stimulus'
import ModalController from '~/controllers/modal_controller'

interface Data {
  performanceType: string
  point: number
}

interface Methods {
  shareX(): void
  handleClickCloseButton(): void
}

interface Computed {
  productImageSrc: string
  jutPurchasedImageUrl: string
}

interface Props {
  isAppAccess: boolean
  currentUserIsIos: boolean
  shareUrl: string
  subText: string
  productImageUrl: string
}

export default Vue.extend<Data, Methods, Computed, Props>({
  data() {
    return {
      performanceType: '',
      point: 0,
    }
  },
  props: {
    isAppAccess: {
      type: Boolean,
    },
    currentUserIsIos: {
      type: Boolean,
    },
    shareUrl: {
      type: String,
    },
    productImageUrl: {
      type: String,
    },
    subText: {
      type: String,
    },
  },
  methods: {
    shareX(): void {
      const { shareUrl, isAppAccess, currentUserIsIos } = this.$props
      if (isAppAccess && currentUserIsIos) {
        window.location.href = shareUrl
      } else {
        window.open(shareUrl)
      }
    },
    handleClickCloseButton(): void {
      const modal = findController<ModalController>('modal')
      modal?.close()
    },
  },
  computed: {
    productImageSrc(): string {
      return this.$props.productImageUrl
    },
    jutPurchasedImageUrl(): string {
      return jusPurchasedImage
    },
  },
  filters: {
    numberFormat(point: number): string {
      return point.toLocaleString()
    },
  },
})
</script>

<style scoped lang="scss">
.just-purchased-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: $space-LL 0;

  &__title {
    z-index: 1;
    text-align: center;
  }

  &__title-image {
    height: auto;
  }

  &__product-wrapper {
    margin: 0 auto;
  }

  &__product-image {
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    object-fit: contain;

    // FIXME: can not find mixin => can not build
    @media screen and (max-width: ($small-tablet - 1px)) {
      width: 240px;
      height: 176px;
    }

    @media screen and (min-width: $small-tablet) {
      width: 312px;
      height: 230px;
    }
  }

  &__x-share-button {
    display: flex;
    font-size: $font-size-LL;
    min-height: 38px;
    min-width: 354px;
    padding: $space-L $space-XXL;

    @media screen and (max-width: ($small-tablet - 1px)) {
      font-size: $font-size-M;
      min-width: 76%;
      padding: $inner-space-S $inner-space-L;
    }
  }

  &__x-icon {
    font-size: $font-size-XL;
  }

  &__close-button {
    font-size: $font-size-LL;
    min-height: 38px;
    min-width: 354px;
    padding: $space-L $space-XXL;

    @media screen and (max-width: ($small-tablet - 1px)) {
      font-size: $font-size-M;
      min-width: 76%;
      padding: $inner-space-S $inner-space-L;
    }
  }

  &__sub-title {
    font-size: $font-size-M;
  }

  &__sub-text {
    font-size: $font-size-S;
  }

  &__button-text {
    font-size: $font-size-L;
  }
}
</style>
