<template>
  <label class="form__radiobox-label">
    <input
      :name="name"
      class="form__radio"
      type="radio"
      :value="value"
      @change="updateValue"
      :disabled="disabled"
      :checked="checked"
    />
    <slot />
  </label>
</template>

<script lang="ts">
import Vue from 'vue'

export interface RadioChangeEvent {
  checked: boolean
  name: string
  value: string
  disabled: boolean
}

export default Vue.extend({
  props: {
    value: {},
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    checked: {
      type: Boolean,
    },
  },
  methods: {
    updateValue(e: Event): void {
      const { target } = e
      if (!target || !(target instanceof HTMLInputElement)) {
        throw new TypeError()
      }
      function toBoolean(data: string): boolean {
        return data.toLowerCase() === 'true'
      }
      const checked =
        typeof target.checked === 'boolean'
          ? target.checked
          : toBoolean(target.value)
      const { name, value } = target
      this.$emit('input', { checked, name, value })
      this.$emit('change', { checked, name, value })
    },
  },
})
</script>
