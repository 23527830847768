<template>
  <form class="form" @submit="submit">
    <slot />
  </form>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  methods: {
    submit(e: Event): void {
      this.$emit('submit', e)
    },
  },
})
</script>
