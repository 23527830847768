<template>
  <div>
    <v-select
      :filterable="false"
      :options="options"
      @input="inputValue"
      @search="onSearch"
      v-model="inputSelected"
      class="vSelect"
      :placeholder="placeholder"
      :disabled="disabled"
      :multiple="multiple"
    >
      <template slot="no-options">
        {{ noOption }}
      </template>
      <template slot="option" slot-scope="option">
        <div class="dCenter">
          {{ option.name }}
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="dCenter">
          {{ option.name }}
        </div>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import vSelect from 'vue-select'

export default Vue.extend({
  components: {
    vSelect,
  },
  data() {
    return {
      inputSelected: null,
    }
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    noOption: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    selected: {
      type: [Object, Array],
    },
    disabled: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.inputSelected = this.selected
  },
  methods: {
    onSearch(search: string, loading: (arg: boolean) => void) {
      this.$emit('search', search, loading)
    },
    inputValue(option: { id: number | string; name: string }): void {
      this.$emit('input', option)
    },
  },
  watch: {
    selected(newVal) {
      this.inputSelected = newVal
    },
  },
})
</script>
<style lang="scss">
.dCenter {
  display: flex;
  align-items: center;
  font-size: $font-size-L;
}

.vSelect {
  .dropdown {
    li {
      border-bottom: 1px solid $co-black;

      &:last-child {
        border-bottom: none;
      }

      & a {
        padding: $font-size-XS $font-size-XL;
        width: 100%;
        font-size: $font-size-L;
        color: $co-dark-gray;
      }
    }
  }
  .dropdown-menu .active > a {
    color: $co-white;
  }

  .vs__search::placeholder {
    color: $co-gray;
    font-size: $font-size-L;
  }
}
</style>
