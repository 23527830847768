<template>
  <div :class="'form__input--select-wrapper ' + selectorWrapperClass">
    <v-select
      :multiple="multiple"
      :taggable="taggable"
      :class="'form__input ' + additionalClassName() + '' + selectorClass"
      :options="options"
      :reduce="(option) => option.value"
      :id="selectorId"
      @input="updateValue"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      v-model="input.selected"
    />
    <input type="hidden" v-model="input.selected" :name="name" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import vSelect from 'vue-select'

export default Vue.extend({
  components: {
    vSelect,
  },
  data() {
    return {
      input: {
        selected: [],
      },
    }
  },
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
    },
    selectorId: {
      type: String,
    },
    selectorClass: {
      type: String,
    },
    selectorWrapperClass: {
      type: String,
    },
  },
  mounted() {
    this.input.selected = this.$props.selected
  },
  methods: {
    updateValue(value: Object | String): void {
      this.$emit('input', value)
      this.$emit('change', value)
    },
    additionalClassName(): string {
      return this.$props.error ? 'form__input--field-error' : ''
    },
  },
})
</script>
