<template>
  <textarea
    class="form__input form__input--textarea"
    @input="updateValue"
    @change="updateValue"
    :value="value"
  />
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    value: {},
  },
  computed: {
    additionalClassName(): string {
      return this.$props.error ? 'form__input--field-error' : ''
    },
  },
  methods: {
    updateValue(e: Event): void {
      const { target } = e
      if (!target || !(target instanceof HTMLTextAreaElement)) {
        throw new TypeError()
      }
      this.$emit('input', target.value)
      this.$emit('change', target.value)
    },
  },
})
</script>
