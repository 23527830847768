<template>
  <div class="form">
    <header :class="$style.Header">
      <h2>選択してください</h2>
    </header>
    <ul>
      <li
        :class="$style.Cell"
        v-for="option in options"
        @click="() => onSelected(option)"
        :key="option.value"
        :id="`posting_method_cell_${option.value}`"
      >
        <div :class="$style.CellInner" role="button">
          {{ option.label }}
          <div :class="checkboxClassName(option)" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

interface Data {}

interface Option {
  value: string
  label: string
}

interface Methods {
  isSelected(option: Option): boolean
  checkboxClassName(option: Option): string
}

interface Computed {}

interface Props {
  selectedOption?: Option
  onSelected(value: string, name: string): void
  options: Option[]
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    onSelected: {
      type: Function,
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectedOption: {
      type: Object,
    },
  },
  methods: {
    isSelected(option: Option): boolean {
      return option.value === this.$props.selectedOption?.value
    },
    checkboxClassName(option: Option): string {
      return this.isSelected(option)
        ? 'form__checkbox form__checkbox--readonly-checked'
        : 'form__checkbox form__checkbox--readonly-checked form__checkbox--readonly-checked-disabled'
    },
  },
})
</script>

<style module lang="scss">
.Header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background: $co-main;
  padding: $space-S;
  box-sizing: border-box;
  color: $co-white;
  z-index: 1;
}

.Cell {
  border-bottom: solid 1px $co-border;
  cursor: pointer;
  min-height: 40px;
  &:hover {
    background: $co-hilight-gray;
  }
}
.CellInner {
  padding-left: $space-M;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.TagList {
  margin: 0 $space-M;
}
.PriceImg {
  max-width: 100%;
  height: auto;
}
</style>
