<template>
  <div class="snackbar">
    <ul>
      <transition-group name="snack">
        <li
          :class="snackClass(snack)"
          v-for="snack in displayedSnacks"
          :key="snack.id"
          aria-label="Snack Bar"
        >
          <i
            :class="`icon jirafficon jirafficon-${snack.icon}`"
            v-if="snack.icon"
            :aria-label="snack.icon"
          ></i>
          {{ snack.title }}
          <i
            class="close-icon jirafficon jirafficon-close"
            @click="removeSnack"
            aria-label="閉じる"
          ></i>
        </li>
      </transition-group>
    </ul>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

type Variant = 'default' | 'success' | 'warning' | 'notice'

export interface SnackAttributes {
  icon?: string
  title: string
  variant?: Variant
}

interface Snack extends SnackAttributes {
  id: number
}

interface Data {
  snacks: Snack[]
  nextSnackId: number
}

interface SnackMethods {
  addSnack(snack: SnackAttributes, activeTime?: number): void
  removeSnack(id: number): void
  snackClass(snack: Snack): string
}

interface Computed {
  displayedSnacks: Snack[]
}
interface Props {}

const MAX_SNACK_ID = 100000
const SNACK_ACTIVE_TIME = 5000

export default Vue.extend<Data, SnackMethods, Computed, Props>({
  data() {
    return {
      snacks: [],
      nextSnackId: 1,
    }
  },
  computed: {
    displayedSnacks(): Snack[] {
      return this.snacks.filter<Snack>(
        (_snack, index): _snack is Snack => index === this.snacks.length - 1
      )
    },
  },
  methods: {
    snackClass(snack): string {
      return `snack snack--${snack.variant || 'default'}`
    },
    addSnack(snack: SnackAttributes, activeTime?: number): number {
      const id = this.nextSnackId
      this.snacks.push({
        variant: 'default',
        id,
        ...snack,
      })

      this.nextSnackId += 1
      if (this.nextSnackId > MAX_SNACK_ID) {
        this.nextSnackId = 1
      }

      setTimeout(() => {
        this.removeSnack(id)
      }, activeTime || SNACK_ACTIVE_TIME)

      return id
    },
    removeSnack(): void {
      this.snacks = new Array<Snack>()
    },
  },
})
</script>

<style scoped lang="scss">
.snackbar {
  position: relative;
  z-index: $z-index-snackbar;
  .snack {
    position: fixed;
    bottom: $space-M;
    right: $space-M;
    padding: $space-M;
    transform: translateY(0px);
    transition: all 0.2s ease-in;
    margin: 0 auto;
    background: $co-white;
    border-radius: $round;
    min-width: 300px;
    box-shadow: $shadow-box;
    font-weight: bold;
    display: flex;
    align-items: center;

    &--notice {
      background: $co-yellow;
      color: $co-white;
    }

    &--success {
      background: $co-green;
      color: $co-white;
    }

    &--warning {
      background: $co-red;
      color: $co-white;
    }
  }
  .close-icon {
    margin-left: auto;
    font-size: $font-size-XL;
    cursor: pointer;
  }
  .icon {
    margin-right: $space-S;
    font-size: $font-size-2XL;
  }
  .snack-enter-active,
  .snack-leave-active {
    transition: all 0.2s ease-in;
  }
  .snack-enter,
  .snack-leave-to {
    transform: translateY(100px);
  }
}
</style>
